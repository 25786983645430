import React, { useEffect, useState } from 'react';
import style from './navbar.module.scss';
import { AccountCircle, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { menuItemType, NavbarComponentProps } from './navbar.type';
import MenuOverlayComponent from './menu-overlay/menu-overlay.component';
import { UserManagementService } from '../../services/user-management/user-management.service';
import { PERMISSIONS } from '../../utils/enum';
import { UserData } from '@hebbale.academy/user-management-proto/pb/um/api/v1/user_model_pb';
import { UserRole } from '@hebbale.academy/user-management-proto/pb/um/api/v1/user_permissions_model_pb';

const NavBarComponent = ({ setActiveMenuItem }: NavbarComponentProps) => {
  const [userInformation, setUserInformation] = useState<UserData.AsObject>();
  const [rolesList, setRolesList] = useState<UserRole.AsObject[]>([]);
  const [currentSelectedBucket, setCurrentSelectedBucket] = useState<string>(
    PERMISSIONS.NO_ROLES_ASSIGNED,
  );

  useEffect(() => {
    const getUserData = async () => {
      const response = await UserManagementService.getUserPermissionsData();
      if ('userName' in response) {
        setUserInformation(response);
        const allSpaceRolesList: UserRole.AsObject[] = [];
        response.spacesList.forEach((spaceItem) => {
          spaceItem.appsList.forEach((spaceApp) => {
            if (spaceApp.userApp?.code === 'CDER') {
              spaceApp.rolesList.forEach((roleItem) => {
                roleItem.modulesList.forEach((moduleItem) => {
                  if (moduleItem.name === 'cloud-editor') {
                    allSpaceRolesList.push(roleItem);
                  }
                });
              });
            }
          });
        });
        if (rolesList.length === 0 && allSpaceRolesList.length) {
          setRolesList(allSpaceRolesList);
          setActiveMenuItem(allSpaceRolesList[0].description);
          setCurrentSelectedBucket(allSpaceRolesList[0].description);
        }
      } else {
        console.error(response);
      }
    };
    getUserData();
  }, [rolesList.length, setActiveMenuItem]);

  const [dropDown, setDropDown] = useState<boolean>(false);

  const menuItems: menuItemType[] = [
    {
      menuName: 'Home',
      path: '/editor',
    },
    {
      menuName: 'Readme',
      path: '/readme',
    },
  ];

  const toggleBucketHandler = (data) => {
    setActiveMenuItem(data.description);
    setCurrentSelectedBucket(data.description);
  };

  return (
    <nav className={style.navbarContainer}>
      {/* <img
        src={BrandIcon}
        className={style.brandIcon}
        width={210}
        height={50}
        alt="Hebbale Academy Logo"
      /> */}
      {/* <div className={style.leftVerticalLine}></div> */}
      <div className={style.cloudEditor}>Cloud Editor</div>
      <div className={style.rightVerticalLine}></div>
      <ul className={style.menuGroup}>
        {menuItems.map((menuItem: menuItemType) => (
          <li key={`${menuItem.menuName}`} className={style.menuItem}>
            <NavLink exact to={menuItem.path} activeClassName={style.active}>
              {menuItem.menuName}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className={style.userInfoContainer}>
        <div className={style.userInfo}>
          <React.Fragment>
            {userInformation && (
              <span className={style.userName}>{userInformation.personalData?.firstName}</span>
            )}
            {currentSelectedBucket !== PERMISSIONS.NO_ROLES_ASSIGNED && (
              <React.Fragment>
                <span className={style.domainName} onClick={() => setDropDown(!dropDown)}>
                  {rolesList.length && `${currentSelectedBucket}`}
                  {dropDown ? (
                    <ArrowDropUp className={style['arrow-drop-icon']} />
                  ) : (
                    <ArrowDropDown className={style['arrow-drop-icon']} />
                  )}
                </span>
                {dropDown && (
                  <MenuOverlayComponent
                    closeDropDown={() => setDropDown(false)}
                    userData={userInformation}
                    rolesList={rolesList}
                    toggleBucketHandler={toggleBucketHandler}
                    currentSelectedBucket={currentSelectedBucket}
                    setCurrentSelectedBucket={setCurrentSelectedBucket}
                    setActiveMenuItem={setActiveMenuItem}
                  />
                )}
              </React.Fragment>
            )}
            {currentSelectedBucket === PERMISSIONS.NO_ROLES_ASSIGNED && (
              <React.Fragment>
                <span className={style.domainName} onClick={() => setDropDown(!dropDown)}>
                  -
                  {dropDown ? (
                    <ArrowDropUp className={style['arrow-drop-icon']} />
                  ) : (
                    <ArrowDropDown className={style['arrow-drop-icon']} />
                  )}
                </span>
                {dropDown && (
                  <MenuOverlayComponent
                    closeDropDown={() => setDropDown(false)}
                    userData={userInformation}
                    rolesList={rolesList}
                    toggleBucketHandler={toggleBucketHandler}
                    currentSelectedBucket={currentSelectedBucket}
                    setCurrentSelectedBucket={setCurrentSelectedBucket}
                    setActiveMenuItem={setActiveMenuItem}
                  />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
        <AccountCircle className={style.userIcon} />
      </div>
    </nav>
  );
};

export default NavBarComponent;
