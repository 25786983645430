import { UserManagementServiceClient } from '@hebbale.academy/user-management-proto/pb/um/api/v1/user_management_grpc_web_pb';
import { GetUserRequest } from '@hebbale.academy/user-management-proto/pb/um/api/v1/user_management_pb';
import { authService } from '../auth/auth.service';
import { RpcError } from 'grpc-web';
import { UserData } from '@hebbale.academy/user-management-proto/pb/um/api/v1/user_model_pb';

const userManagementServiceClient = new UserManagementServiceClient(
  process.env.REACT_APP_PROTO_USER_MANAGEMENT_ENDPOINT_URL!,
  null,
  null,
);

export const UserManagementService = {
  getUserPermissionsData: async (): Promise<UserData.AsObject | RpcError> => {
    const request = new GetUserRequest();
    const metaData = await authService.createMetaData();
    return new Promise<UserData.AsObject | RpcError>((resolve, reject) => {
      userManagementServiceClient.getUser(request, metaData, (error: RpcError, response) => {
        error ? reject(error) : resolve(response.toObject().userData!);
      });
    }).catch((err) => err);
  },
};
