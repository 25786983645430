import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { EditConfirmationBoxProps } from './edit-confirmation.types';

const EditConfirmationBox = ({
  showConfirmationStatus,
  selectedProjectFileInfo,
  handleClose,
  handleSave,
}: EditConfirmationBoxProps) => {
  return (
    <Dialog
      open={showConfirmationStatus}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Do you want to save changes to this file?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Project Name :{' '}
          <mark>
            {selectedProjectFileInfo && selectedProjectFileInfo.split('|')[0].split(':')[1]}
          </mark>
        </DialogContentText>
        <br />
        <DialogContentText>
          Updating file content against :{' '}
          <mark>
            {selectedProjectFileInfo && selectedProjectFileInfo.split('|')[1].split(':')[1]}
          </mark>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditConfirmationBox;
