import { UserRole } from '@hebbale.academy/user-management-proto/pb/um/api/v1/user_permissions_model_pb';
import { CheckCircle, PowerSettingsNew } from '@mui/icons-material';
import { FunctionComponent } from 'react';
import { authService } from '../../../services/auth/auth.service';
import { PERMISSIONS } from '../../../utils/enum';
import styles from './menu-overlay.module.scss';

const MenuOverlayComponent: FunctionComponent<{
  closeDropDown: () => void;
  userData: any;
  rolesList: UserRole.AsObject[];
  toggleBucketHandler;
  currentSelectedBucket: string;
  setCurrentSelectedBucket;
  setActiveMenuItem;
}> = ({
  closeDropDown,
  userData,
  rolesList,
  toggleBucketHandler,
  currentSelectedBucket,
  setCurrentSelectedBucket,
  setActiveMenuItem,
}) => {
  const onLogout = () => {
    authService.logout();
  };

  return (
    <div className={styles.menuOverlay} onClick={() => closeDropDown()}>
      {userData && (
        <div className={styles.menuGroup}>
          <div
            className={styles.fullName}
          >{`${userData.personalData.firstName} ${userData.personalData.lastName}`}</div>
          <div className={styles.userName}>{`Username: ${userData.userName}`}</div>
        </div>
      )}
      {currentSelectedBucket !== PERMISSIONS.NO_ROLES_ASSIGNED && (
        <div className={styles.menuGroup}>
          <div className={styles.menuHeading}>Switch Bucket</div>
          {rolesList.map((roleItem) =>
            currentSelectedBucket === roleItem.description ? (
              <>
                <div key={roleItem.code} className={`${styles.menuItem} ${styles.active}`}>
                  <CheckCircle className={styles.menuIcon} />
                  {roleItem.description}
                </div>
              </>
            ) : (
              <>
                <div
                  className={styles.menuItem}
                  onClick={() => {
                    toggleBucketHandler(roleItem);
                    setCurrentSelectedBucket(roleItem.description);
                    setActiveMenuItem(roleItem.description);
                  }}
                  key={roleItem.code}
                >
                  {roleItem.description}
                </div>
              </>
            ),
          )}
        </div>
      )}
      <div className={styles.menuGroup}>
        <div className={`${styles.menuItem} ${styles.active}`} onClick={onLogout}>
          <PowerSettingsNew className={styles.menuIcon} />
          Logout
        </div>
      </div>
    </div>
  );
};

export default MenuOverlayComponent;
