import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ProjectContentManagementService,
  ProjectManagementService,
} from '../../services/project-manager/project-manager.service';
import { ReactComponent as FileIcon } from '../../assets/file-icon.svg';
import { ReactComponent as UnLocked } from '../../assets/unlocked.svg';
import { Save, Backup, Edit, Refresh } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import HaEditorComponent from '../ha-editor/ha-editor.component';
import { getHTMLTemplate } from '../ha-editor/templates/ha-editor-templates';
import { saveAs } from 'file-saver';
import { PERMISSIONS } from '../../utils/enum';
import SyncLoader from 'react-spinners/SyncLoader';
import EditConfirmationBox from '../../components/dialog-box/edit-confirmation';
import {
  Base64,
  getCurrentTime,
  getItemPropertiesFromSessionStorage,
  setItemPropertiesToSessionStorage,
} from '../../utils/utils';
import {
  DialogBoxActions,
  DialogBoxStateType,
  HomeComponentProps,
  ProjectDataType,
} from './home.types';
import style from './home.module.scss';
import ProjectExplorerV2Component from '../../components/project-explorer/project-explorer.componentV2';
import { Editor } from 'tinymce';

const HomeComponentV2 = ({ activeMenuItem, showToast }: HomeComponentProps) => {
  const [selectedFileName, setSelectedFileName] = React.useState('');
  const [projectData, setProjectData] = React.useState<ProjectDataType[] | null>(null);
  const tinymceEditoryRef = useRef<Editor>(null);
  const [savingToCloud, setSavingToCloud] = React.useState<boolean>(false);
  const [enable, setEnable] = React.useState<boolean>(true);
  const [dialogBoxState, setDialogBoxState] = React.useState<DialogBoxStateType>({
    isVisible: false,
    action: DialogBoxActions.HANDLE_SAVE_TO_CLOUD,
  });
  const [loadedHtmlContent, setLoadedHtmlContent] = useState('');
  const getItems = () => {
    ProjectManagementService.getProjects().then((responseData: any) =>
      setProjectData(responseData.projectTemplatesList),
    );
  };

  const loadBucketContent = useCallback(() => {
    setProjectData(null);
    getItems();
    setSelectedFileName('');
  }, []);

  useEffect(() => {
    if (!(activeMenuItem === PERMISSIONS.NO_ROLES_ASSIGNED)) {
      loadBucketContent();
    }
  }, [loadBucketContent, activeMenuItem]);

  const resetEditorHtmlAndLoadContents = () => {
    loadBucketContent();
    tinymceEditoryRef.current!.setContent('');
  };
  const onRefreshHandler = () => {
    const currentHtmlContent = tinymceEditoryRef.current!.getContent({ format: 'html' });
    if (currentHtmlContent && loadedHtmlContent && loadedHtmlContent !== currentHtmlContent) {
      setDialogBoxState({ isVisible: true, action: DialogBoxActions.ON_REFRESH_HANDLER });
    } else {
      showToast('Refreshing data...');
      resetEditorHtmlAndLoadContents();
      setItemPropertiesToSessionStorage();
    }
    setLoadedHtmlContent('');
  };

  const saveContentToCloud = async () => {
    const { tmceItemPath, tmceFileName, tmceItemType, tmceUpdatedOn, tmceContentDocId } =
      getItemPropertiesFromSessionStorage();

    if (tmceItemPath.length === 0) {
      // alert('Select and edit file content to Save -> file -> cloud');
      showToast('Select and edit file content to Save -> file -> cloud');
    } else {
      const editorHtmlContent: string = tinymceEditoryRef.current!.getContent({ format: 'html' });
      let htmlContentWrapper = getHTMLTemplate().replace('#editorContent', editorHtmlContent);
      // TODO: Temp fix to add space,revisit and fix gracefully
      htmlContentWrapper = htmlContentWrapper.replaceAll('~~', '&nbsp;');
      setSavingToCloud(true);
      const updateItemResponse: any = await ProjectManagementService.updateItem(
        `${tmceItemPath}/${tmceFileName}`,
        tmceItemType,
        btoa(Base64._utf8_encode(htmlContentWrapper)),
        activeMenuItem,
        tmceUpdatedOn,
        tmceContentDocId,
      );
      setSavingToCloud(false);
      if (updateItemResponse && updateItemResponse.status === 1) {
        setLoadedHtmlContent(editorHtmlContent);
        return true;
      }
    }
    return false;
  };

  const handleSaveToCloud = () =>
    !enable &&
    setDialogBoxState({ action: DialogBoxActions.HANDLE_SAVE_TO_CLOUD, isVisible: true });

  let taskId = sessionStorage.getItem('taskId') || '';
  let projectTemplateId = sessionStorage.getItem('projectTemplateId') || '';

  const handleUnLock = async () => {
    const response: any = await ProjectContentManagementService.unlockContentLock(
      projectTemplateId,
      taskId,
    );
    if (response.status === 2 && response.lockOwner === 'none') {
      showToast('No document exits for unlock');
    } else if (response.status === 2 && response.lockOwner) {
      showToast(
        `The file is already locked, and ${response.lockOwner} is now editing the content.`,
      );
    } else {
      showToast('The file is unlocked.');
    }
  };

  const time = getCurrentTime();

  const onSaveHandler = () => {
    let editorHtmlContent: string = tinymceEditoryRef.current!.getContent({ format: 'html' });
    editorHtmlContent = getHTMLTemplate()
      .replace('#editorContent', editorHtmlContent)
      .replaceAll(' ', '');
    // TODO: Temp fix to add space,revisit and fix gracefully
    editorHtmlContent = editorHtmlContent.replaceAll('~~', '&nbsp;');
    let editedContentLength = editorHtmlContent.length;
    const u8arr = new Uint8Array(editedContentLength);
    while (editedContentLength--) {
      u8arr[editedContentLength] = editorHtmlContent.charCodeAt(editedContentLength);
    }
    saveAs(new File([u8arr], `${time}-topic.html`, { type: 'html' }));
  };

  const closeDialogBox = () =>
    setDialogBoxState((prevState) => ({ ...prevState, isVisible: false }));

  const handleDialogClose = () => {
    closeDialogBox();
    if (dialogBoxState.action === 'onRefreshHandler') {
      showToast('Refreshing data...');
      resetEditorHtmlAndLoadContents();
    }
  };

  const handleDialogSave = async () => {
    closeDialogBox();
    const saveSuccessful = await saveContentToCloud();
    if (saveSuccessful) {
      showToast('Saved to cloud!');
      if (dialogBoxState.action === 'onRefreshHandler') {
        resetEditorHtmlAndLoadContents();
      }
    } else {
      showToast('Could not save to cloud due to an error! Check console for more details..');
    }
  };

  const enableEdit = async () => {
    const response: any = await ProjectContentManagementService.acquireContentLock(
      projectTemplateId,
      taskId,
    );
    if (response.status === 1) {
      tinymceEditoryRef.current!.setMode('design');
      showToast('Editing mode Enabled');
      setEnable(false);
    } else {
      showToast(
        `The file is already locked, and ${response.lockOwner} is now editing the content.`,
      );
    }
  };

  return (
    <div className={style.homeContainer}>
      {dialogBoxState.isVisible && (
        <EditConfirmationBox
          showConfirmationStatus={dialogBoxState.isVisible}
          selectedProjectFileInfo={selectedFileName}
          handleClose={handleDialogClose}
          handleSave={handleDialogSave}
        ></EditConfirmationBox>
      )}
      {activeMenuItem === PERMISSIONS.NO_ROLES_ASSIGNED ? (
        <div className={style.roleDoesNotExistMessage}>
          You aren't authorized to access this Cloud Editor
        </div>
      ) : (
        <>
          {savingToCloud && (
            <div className={style.loaderContainer}>
              <SyncLoader loading={true} color="#3CD27D" />
              <p>Saving...</p>
            </div>
          )}
          <div className={style.projectExplorerItem}>
            <div className={style.headerSection}>
              <div>Select Project</div>
              <div title="Refresh">
                <Refresh
                  onClick={onRefreshHandler}
                  style={{ height: '25px', width: '25px' }}
                  className={style.refreshIcon}
                />
              </div>
            </div>
            <div className={style.projectExplorerBlock}>
              <ProjectExplorerV2Component
                selectedFileName={selectedFileName}
                setSelectedFileName={setSelectedFileName}
                projectData={projectData}
                tinymceEditoryRef={tinymceEditoryRef}
                activeMenuItem={activeMenuItem}
                loadedHtmlContent={loadedHtmlContent}
                setLoadedHtmlContent={setLoadedHtmlContent}
                saveContentToCloud={saveContentToCloud}
                setEnable={setEnable}
              ></ProjectExplorerV2Component>
            </div>
          </div>
          <div className={style.editorItem}>
            <div className={style.editorHeaderSection}>
              <p className={style.fileName}>
                {selectedFileName.length !== 0 && (
                  <span className={style.fileIcon}>
                    <FileIcon />
                  </span>
                )}
                {selectedFileName.length !== 0 ? selectedFileName : 'Editor'}
              </p>
              {!!selectedFileName.length && (
                <div className={style.extraButtons}>
                  <Tooltip title="Edit">
                    <Edit onClick={enableEdit} />
                  </Tooltip>
                  <Tooltip title="Unlock Document">
                    <UnLocked onClick={handleUnLock}></UnLocked>
                  </Tooltip>
                  <Tooltip title="Save Local">
                    <Save onClick={onSaveHandler}></Save>
                  </Tooltip>
                  <Tooltip title={!enable ? 'Save to Cloud' : ''}>
                    <Backup
                      onClick={handleSaveToCloud}
                      className = {!enable? style.enabled : style.disabled}
                    ></Backup>
                  </Tooltip>
                </div>
              )}
            </div>
            <div>
              <HaEditorComponent tinymceEditoryRef={tinymceEditoryRef}></HaEditorComponent>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HomeComponentV2;
