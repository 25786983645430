import React, { useEffect, useMemo, useState } from 'react';
import { ProjectManagementService } from '../../services/project-manager/project-manager.service';
import style from './project-explorer.module.scss';
import FolderIcon from '@mui/icons-material/Folder';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import { LOADING_TEXTS } from '../../utils/enum';
import { Base64, setItemPropertiesToSessionStorage } from '../../utils/utils';
import EditConfirmationBox from '../dialog-box/edit-confirmation';
import { NodeDataType, ProjectExplorerComponentPropsV2 } from './project-explorer.types';

const ProjectExplorerV2Component = ({
  projectData,
  saveContentToCloud,
  tinymceEditoryRef,
  activeMenuItem,
  selectedFileName,
  loadedHtmlContent,
  setLoadedHtmlContent,
  setSelectedFileName,
  setEnable,
}: ProjectExplorerComponentPropsV2) => {
  useEffect(() => {
    setItemPropertiesToSessionStorage();
  }, []);

  const [selectValue, setSelectValue] = React.useState('');
  const [loadingText, setLoadingText] = useState<string>('');
  const [activeNodeData, setActiveNodeData] = useState<NodeDataType>({
    name: '',
    itemPath: '',
  });
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  useEffect(() => {
    const animateLoadingText = () => {
      !projectData &&
        setLoadingText((prevLoadingText) => {
          if (prevLoadingText === `${LOADING_TEXTS.PROJECTS}...`) {
            return LOADING_TEXTS.PROJECTS;
          } else {
            return `${prevLoadingText}.`;
          }
        });
    };
    setLoadingText(LOADING_TEXTS.PROJECTS);
    const setAnimationInterval = !projectData && setInterval(animateLoadingText, 750);
    const clearAnimationOnTimeout =
      setAnimationInterval &&
      setTimeout(() => {
        clearInterval(setAnimationInterval);
        setLoadingText('Failed to load projects. Click on Refresh to try again..');
      }, 20000);

    const clearIntervalAndTimeout = () => {
      if (setAnimationInterval && clearAnimationOnTimeout) {
        clearInterval(setAnimationInterval);
        clearTimeout(clearAnimationOnTimeout);
      }
    };

    return clearIntervalAndTimeout;
  }, [projectData]);

  const setTemplateId = (projectTemplateId: string) => {
    sessionStorage.setItem('projectTemplateId', projectTemplateId || '');
  };
  const onChangeProject = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;
    setSelectValue(value);
    setTemplateId(value);
  };

  const handleDialogClose = () => {
    setShowDialogBox(false);
    getFileContentAndSetFilename(
      activeNodeData,
      `${activeNodeData.itemPath.slice(1)}/${activeNodeData.name}`,
    );
  };

  const handleDialogSave = async () => {
    setShowDialogBox(false);
    const saveSuccessful = await saveContentToCloud();
    if (saveSuccessful) {
      getFileContentAndSetFilename(
        activeNodeData,
        `${activeNodeData.itemPath.slice(1)}/${activeNodeData.name}`,
      );
    }
  };

  const setEditorContent = (dataToSet: string) => {
    tinymceEditoryRef.current!.setContent(Base64._utf8_decode(atob(dataToSet)), {
      format: 'raw',
    });
    tinymceEditoryRef.current?.setMode('readonly');
  };

  const getItemContent = async (contentPathName: string) => {
    const getItemContent = await ProjectManagementService.getItemContent(
      contentPathName,
      activeMenuItem,
    );

    // we need to clear the undoManager stack after changing the editor content to not get the previous data on undo.
    tinymceEditoryRef.current!.undoManager.clear();
    setEditorContent(getItemContent?.itemContent || '');
    setLoadedHtmlContent(tinymceEditoryRef.current!.getContent());
  };

  const getFileContentAndSetFilename = (nodeData: NodeDataType, filePath: string) => {
    setSelectedFileName(
      `Project Name : ${nodeData.itemPath.split('/')[0]} | File Name : ${nodeData.name}`,
    );

    getItemContent(filePath);
    setItemPropertiesToSessionStorage(
      nodeData.itemPath.slice(0, nodeData.itemPath.lastIndexOf('/')),
      nodeData.name,
      '2',
      nodeData.contentDocId,
      nodeData.updatedOn,
    );
  };

  const taskContentUrls = useMemo(
    () =>
      projectData && projectData.length > 0
        ? projectData
            .find((item) => item.data.id === selectValue)
            ?.data.projectActivitiesList.flatMap((item) =>
              item.projectActivityTasksList.map((item) => item.taskContentUrl),
            )
            .filter((contentUrl) => contentUrl.includes('.html'))
        : [],
    [projectData, selectValue],
  );

  const setId = (taskId: string) => {
    sessionStorage.setItem('taskId', taskId || '');
  };

  const handleTaskClick = (filePath: string) => {
    if (!filePath.includes('.html')) {
      alert('Only html file editing is supported');
    } else {
      setActiveNodeData({ name: filePath.split('/')[2], itemPath: filePath });
      const currentHtmlContent = tinymceEditoryRef.current!.getContent({ format: 'html' });
      if (currentHtmlContent && loadedHtmlContent && loadedHtmlContent !== currentHtmlContent) {
        setShowDialogBox(true);
      } else {
        getFileContentAndSetFilename(
          { name: filePath.split('/')[2], itemPath: filePath },
          filePath,
        );
      }
      setEnable(true);
    }
    setId(filePath);
  };

  useEffect(() => {
    projectData && projectData.length === 0 && setSelectValue('');
  }, [projectData]);

  return (
    <React.Fragment>
      {projectData ? (
        <section>
          <div className={style.dropdownWrapper}>
            <FolderIcon className={style.folder} />
            <select
              value={selectValue}
              onChange={onChangeProject}
              title={projectData.find((item) => item.data.id === selectValue)?.data.name}
            >
              {projectData.map((item, index) => (
                <option key={item.data.id + index} value={item.data.id}>
                  {`${item.data.name}`}
                </option>
              ))}
            </select>
          </div>
          <ul className={style.ul}>
            {taskContentUrls?.map((contentUrl, index) => (
              <li
                key={contentUrl + index}
                onClick={() => handleTaskClick(contentUrl)}
                className={style.column}
              >
                <InsertDriveFileOutlined className={style.projectName} />
                {contentUrl.split('/')[2] || contentUrl}
              </li>
            ))}
          </ul>
        </section>
      ) : (
        <p className={style.loadingText}>{loadingText}</p>
      )}
      {showDialogBox && (
        <EditConfirmationBox
          showConfirmationStatus={showDialogBox}
          selectedProjectFileInfo={selectedFileName}
          handleClose={handleDialogClose}
          handleSave={handleDialogSave}
        ></EditConfirmationBox>
      )}
    </React.Fragment>
  );
};

export default ProjectExplorerV2Component;
