/* tslint:disable */
import {
  addNotePlugin,
  addTipPlugin,
  addCheckPlugin,
  addCautionPlugin,
  addWarningPlugin,
  infoAdminationPlugin,
  updateIconsPlugin,
} from '../plugins/adminations/plugin';
import { addAccordionPlugin } from '../plugins/accordion/plugin';
import { haPreviewPlugin } from '../plugins/preview/plugin';
import { haImagePlugin } from '../plugins/image/plugin';
import { haMediaPlugin } from '../plugins/media/plugin';

declare const window: any;

// theme is now a global variable
const theme = 'dark';

const initEditorConfiguration = {
  height: '85vh',
  skin_url: `/skins/ui/${theme}`,
  forced_root_block: '',
  cleanup: true,
  remove_linebreaks: true,
  convert_newlines_to_brs: false,
  entities: 'nbsp',
  browser_spellcheck: true,
  entity_encoding: 'raw',
  paste_auto_cleanup_on_paste: true,
  content_css: [
    '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
    '//pro.fontawesome.com/releases/v5.10.0/css/all.css',
    '/assets/custom-tinymise.css',
    `/skins/content/${theme}/content.css`,
  ],
  inline_styles: true,
  statusbar: false,
  menubar: false,
  fontsize_formats:
    '10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 21pt 23pt 24pt 25pt 26pt 27pt 28pt 29pt 30pt 31pt 32pt 33pt 34pt 35pt 36pt',
  paste_data_images: true,
  textcolor_cols: '8',

  images_dataimg_filter(img) {
    return img.hasAttribute('internal-blob');
  },

  plugins: [
    'lists link haImagePlugin haMediaPlugin paste codesample table haPreviewPlugin textcolor infoToolbarButton addNotePlugin addTipPlugin addCheckPlugin addWarningPlugin addCautionPlugin updateIconsPlugin addAccordionPlugin noneditable',
  ],
  codesample_global_prismjs: true,

  // noneditable_noneditable_class: 'mceNonEditable',
  // noneditable_editable_class: 'mceEditable',

  // forecolor -> Text Color
  // backgroundcolor -> backcolor
  toolbar:
    'fontselect | fontsizeselect | forecolor backcolor bold italic underline strikethrough superscript subscript| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link haImagePlugin haMediaPlugin table codesample haPreviewPlugin lineheight | infoToolbarButton addNotePlugin addTipPlugin addCheckPlugin addWarningPlugin addCautionPlugin addAccordionPlugin',
  content_style: `@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap'); body { font-family: Poppins; }
    ::-webkit-scrollbar {
      width: 0.35rem;
      height: 0.35rem;
    }
    ::-webkit-scrollbar-track {
      background: inherit;
    }
    ::-webkit-scrollbar-thumb {
      background: #3cd27d;
      border-radius: 0.5rem;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(60, 210, 125, 0.8);
    }
    img, video {
      max-width: 900px;
    }`,

  font_formats: 'Poppins=Poppins;Courier New=courier new,courier;',

  contextmenu: 'image',

  media_dimensions: true,

  codesample_languages: [
    { text: 'HTML/XML', value: 'markup' },
    { text: 'JavaScript', value: 'javascript' },
    { text: 'TypeScript', value: 'typescript' },
    { text: 'JSON', value: 'json' },
    { text: 'Shell', value: 'shell-session' },
    { text: 'YAML', value: 'yml' },
    { text: 'CSS', value: 'css' },
    { text: 'PHP', value: 'php' },
    { text: 'Ruby', value: 'ruby' },
    { text: 'Python', value: 'python' },
    { text: 'Java', value: 'java' },
    { text: 'C', value: 'c' },
    { text: 'C#', value: 'csharp' },
    { text: 'C++', value: 'cpp' },
    { text: 'Text', value: 'text' },
  ],

  init_instance_callback: (editor) => {
    editor.on('NodeChange', function (e) {
      e.element.parentNode.querySelectorAll('img').forEach((img) => {
        const randomId = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
        img.setAttribute('id', 'ha-img-' + randomId);
        img.setAttribute('onclick', 'writeLED(this.id)');
      });
    });

    const textColorIcon = document.getElementById('tox-icon-text-color__color');
    const highlightColorIcon = document.getElementById('tox-icon-highlight-bg-color__color');
    const fontSizeButtonsGroup = document.getElementsByClassName(
      'tox-tbtn tox-tbtn--select tox-tbtn--bespoke',
    );
    textColorIcon?.getAttribute('fill') === '#000000' &&
      textColorIcon.setAttribute('fill', 'white');
    highlightColorIcon?.getAttribute('fill') === '#000000' &&
      highlightColorIcon.setAttribute('fill', 'transparent');
    fontSizeButtonsGroup[0]?.setAttribute('style', 'user-select:none;width:99px');
    fontSizeButtonsGroup[1]?.setAttribute('style', 'user-select:none;width:73px');
  },

  setup: (editor) => {
    /**
     * Register custom buttons to add Adminations
     * Available Adminations
     * - Info
     * - Important
     * */
    if (window && window.tinymce) {
      // Here we can add plugin
      window.tinymce.PluginManager.add('infoToolbarButton', infoAdminationPlugin);
      window.tinymce.PluginManager.add('addNotePlugin', addNotePlugin);
      window.tinymce.PluginManager.add('addTipPlugin', addTipPlugin);
      window.tinymce.PluginManager.add('addCheckPlugin', addCheckPlugin);
      window.tinymce.PluginManager.add('addWarningPlugin', addWarningPlugin);
      window.tinymce.PluginManager.add('addCautionPlugin', addCautionPlugin);
      window.tinymce.PluginManager.add('updateIconsPlugin', updateIconsPlugin);
      window.tinymce.PluginManager.add('addAccordionPlugin', addAccordionPlugin);
      window.tinymce.PluginManager.add('haPreviewPlugin', haPreviewPlugin(editor, window.tinymce));
      window.tinymce.PluginManager.add('haImagePlugin', haImagePlugin(editor, window.tinymce));
      window.tinymce.PluginManager.add('haMediaPlugin', haMediaPlugin(editor, window.tinymce));
    }
  },
};

export default initEditorConfiguration;
