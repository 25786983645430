import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProjectManagementService } from '../../services/project-manager/project-manager.service';
import { ReactComponent as FileIcon } from '../../assets/file-icon.svg';
import { ReactComponent as LockedIcon } from '../../assets/locked.svg';
import { ReactComponent as PreviewIcon } from '../../assets/preview.svg';
import { ReactComponent as UnLocked } from '../../assets/unlocked.svg';
import { Save, Backup, Refresh } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import HaEditorComponent from '../ha-editor/ha-editor.component';
import { getHTMLTemplate } from '../ha-editor/templates/ha-editor-templates';
import { saveAs } from 'file-saver';
import { ITEM_TYPES, PERMISSIONS } from '../../utils/enum';
import SyncLoader from 'react-spinners/SyncLoader';
import EditConfirmationBox from '../../components/dialog-box/edit-confirmation';
import {
  Base64,
  getItemPropertiesFromSessionStorage,
  setItemPropertiesToSessionStorage,
} from '../../utils/utils';
import { DialogBoxActions, DialogBoxStateType, HomeComponentProps } from './home.types';
import {
  FilesAndFoldersChildrenType,
  FilesAndFoldersDataType,
} from '../../components/project-explorer/project-explorer.types';
import style from './home.module.scss';
import ProjectExplorerComponent from '../../components/project-explorer/project-explorer.component';

const HomeComponent = ({ activeMenuItem, showToast }: HomeComponentProps) => {
  const [selectedFileName, setSelectedFileName] = React.useState('');
  const [filesAndFoldersData, setFilesAndFoldersData] = React.useState<FilesAndFoldersDataType| null >(null);
  const tinymceEditoryRef = useRef<any>(null);
  const [savingToCloud, setSavingToCloud] = React.useState<boolean>(false);
  const [dialogBoxState, setDialogBoxState] = React.useState<DialogBoxStateType>({
    isVisible: false,
    action: DialogBoxActions.HANDLE_SAVE_TO_CLOUD,
  });
  const [loadedHtmlContent, setLoadedHtmlContent] = useState('');
  const updateFileStructure = (filesArr: FilesAndFoldersDataType['children']) => {
    filesArr.forEach((arrayElem, index) => {
      // check if the element is not a folder and has an empty children array
      if (
        arrayElem.itemType === ITEM_TYPES.FILE &&
        arrayElem.children &&
        arrayElem.children.length === 0
      ) {
        // unassign the children key from the element
        filesArr[index].children = undefined;
      }
    });
  };

  const iterateAndSortFilesArr = useCallback((filesArr) => {
    let sortedFilesArr: FilesAndFoldersDataType['children'] = filesArr;
    if (sortedFilesArr) {
      if (sortedFilesArr.length > 1) {
        // fix the files being shown as folders
        updateFileStructure(sortedFilesArr);
        sortedFilesArr.sort((a: FilesAndFoldersChildrenType, b: FilesAndFoldersChildrenType) =>
          sortByLiterals(a, b),
        );
      }
      sortedFilesArr.forEach((fileObj: FilesAndFoldersChildrenType, index: number) => {
        if (fileObj.children) {
          sortedFilesArr[index] = {
            ...fileObj,
            children: iterateAndSortFilesArr(fileObj.children),
          };
        }
      });
    }
    return sortedFilesArr;
  }, []);

  const sortByLiterals = (
    objA: { name: string; itemType: number; children?: FilesAndFoldersChildrenType[] },
    objB: { name: string; itemType: number; children?: FilesAndFoldersChildrenType[] },
  ) => {
    if (objA.name && objB.name) {
      if (objA.name > objB.name) {
        const aIntString = objA.name.replace(/\D/g, '');
        const bIntString = objB.name.replace(/\D/g, '');
        if (parseInt(aIntString) > parseInt(bIntString)) {
          return 1;
        }
        if (parseInt(aIntString) < parseInt(bIntString)) {
          return -1;
        }
        return 0;
      }
      if (objA.name < objB.name) {
        return -1;
      }
    }
    return 0;
  };
  const getItems = useCallback(
    async (groupItem) => {
      const getItemsData: any = await ProjectManagementService.getItems(groupItem);
      getItemsData &&
        setFilesAndFoldersData({
          name: 'Project Explorer',
          children: iterateAndSortFilesArr(
            JSON.parse(
              JSON.stringify(getItemsData)
                .replace(/"childrenList":/g, '"children":')
                .replace(/,"children":\[\]}/g, '}'),
            ).children,
          ),
          isOpen: true,
        });
    },
    [iterateAndSortFilesArr],
  );

  const loadBucketContent = useCallback(() => {
    setFilesAndFoldersData(null);
    getItems(activeMenuItem);
    setSelectedFileName('');
  }, [getItems, activeMenuItem]);

  useEffect(() => {
    if (!(activeMenuItem === PERMISSIONS.NO_ROLES_ASSIGNED)) {
      loadBucketContent();
    }
  }, [loadBucketContent, activeMenuItem]);

  const resetEditorHtmlAndLoadContents = () => {
    loadBucketContent();
    tinymceEditoryRef.current!.setContent('');
  };

  const onRefreshHandler = () => {
    const currentHtmlContent = tinymceEditoryRef.current!.getContent({ format: 'html' });
    if (currentHtmlContent && loadedHtmlContent && loadedHtmlContent !== currentHtmlContent) {
      setDialogBoxState({ isVisible: true, action: DialogBoxActions.ON_REFRESH_HANDLER });
    } else {
      showToast('Refreshing data...');
      resetEditorHtmlAndLoadContents();
      setItemPropertiesToSessionStorage();
    }
    setLoadedHtmlContent('');
  };

  const saveContentToCloud = async () => {
    const { tmceItemPath, tmceFileName, tmceItemType, tmceUpdatedOn, tmceContentDocId } =
      getItemPropertiesFromSessionStorage();
    if (tmceItemPath.length === 0) {
      alert('Select and edit file content to Save -> file -> cloud');
    } else {
      const editorHtmlContent: string = tinymceEditoryRef.current!.getContent({ format: 'html' });
      let htmlContentWrapper = getHTMLTemplate().replace('#editorContent', editorHtmlContent);
      // TODO: Temp fix to add space,revisit and fix gracefully
      htmlContentWrapper = htmlContentWrapper.replaceAll('~~', '&nbsp;');
      setSavingToCloud(true);
      const updateItemContent: any = await ProjectManagementService.updateItem(
        `${tmceItemPath}/${tmceFileName}`,
        tmceItemType,
        btoa(Base64._utf8_encode(htmlContentWrapper)),
        activeMenuItem,
        tmceUpdatedOn,
        tmceContentDocId,
      );
      // HOTFIX: Use a timeout based switch to not show the overlay infinitely
      const overlayTimeout = setTimeout(() => {
        setSavingToCloud(false);
        showToast('Saving to cloud might have failed! Check console for more details..');
      }, 20000);
      if (updateItemContent) {
        clearTimeout(overlayTimeout);
        setSavingToCloud(false);
        if (updateItemContent?.status === 1) {
          showToast('Saved to cloud!');
          setLoadedHtmlContent(editorHtmlContent);
          return true;
        } else {
          showToast('Could not save to cloud due to an error! Check console for more details..');
        }
      }
    }
    return false;
  };

  const handleSaveToCloud = () =>
    setDialogBoxState({ action: DialogBoxActions.HANDLE_SAVE_TO_CLOUD, isVisible: true });

  const today = new Date();
  const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

  const onSaveHandler = () => {
    let editorHtmlContent: string = tinymceEditoryRef.current!.getContent({ format: 'html' });
    editorHtmlContent = getHTMLTemplate()
      .replace('#editorContent', editorHtmlContent)
      .replaceAll(' ', '');
    // TODO: Temp fix to add space,revisit and fix gracefully
    editorHtmlContent = editorHtmlContent.replaceAll('~~', '&nbsp;');
    const bstr = editorHtmlContent;
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    saveAs(new File([u8arr], `${time}-topic.html`, { type: 'html' }));
  };

  const closeDialogBox = () =>
    setDialogBoxState((prevState) => ({ ...prevState, isVisible: false }));

  const handleDialogClose = () => {
    closeDialogBox();
    if (dialogBoxState.action === 'onRefreshHandler') {
      showToast('Refreshing data...');
      resetEditorHtmlAndLoadContents();
    }
  };

  const handleDialogSave = async () => {
    closeDialogBox();
    let toastMessageSuffix = '';
    const saveSuccessful = await saveContentToCloud();
    if (saveSuccessful && dialogBoxState.action === 'onRefreshHandler') {
      toastMessageSuffix = ' Refreshing data...';
      resetEditorHtmlAndLoadContents();
    }
    showToast('Saved to cloud!' + toastMessageSuffix);
  };

  return (
    <div className={style.homeContainer}>
      {dialogBoxState.isVisible && (
        <EditConfirmationBox
          showConfirmationStatus={dialogBoxState.isVisible}
          selectedProjectFileInfo={selectedFileName}
          handleClose={handleDialogClose}
          handleSave={handleDialogSave}
        ></EditConfirmationBox>
      )}
      {activeMenuItem === PERMISSIONS.NO_ROLES_ASSIGNED ? (
        <div className={style.roleDoesNotExistMessage}>
          You aren't authorized to access this Cloud Editor
        </div>
      ) : (
        <>
          {savingToCloud && (
            <div className={style.loaderContainer}>
              <SyncLoader loading={true} color="#3CD27D" />
              <p>Saving...</p>
            </div>
          )}
          <div className={style.projectExplorerItem}>
            <div className={style.headerSection}>
              <div>File Explorer</div>
              <div title="Refresh">
                <Refresh
                  onClick={onRefreshHandler}
                  style={{ height: '25px', width: '25px' }}
                  className={style.refreshIcon}
                />
              </div>
            </div>
            <div className={style.projectExplorerBlock}>
              <ProjectExplorerComponent
                selectedFileName={selectedFileName}
                setSelectedFileName={setSelectedFileName}
                filesAndFoldersData={filesAndFoldersData}
                tinymceEditoryRef={tinymceEditoryRef}
                activeMenuItem={activeMenuItem}
                loadedHtmlContent={loadedHtmlContent}
                setLoadedHtmlContent={setLoadedHtmlContent}
                saveContentToCloud={saveContentToCloud}
              ></ProjectExplorerComponent>
            </div>
          </div>
          <div className={style.editorItem}>
            <div className={style.editorHeaderSection}>
              <p className={style.fileName}>
                {selectedFileName.length !== 0 && (
                  <span className={style.fileIcon}>
                    <FileIcon />
                  </span>
                )}
                {selectedFileName.length !== 0 ? selectedFileName : 'Editor'}
              </p>
              {selectedFileName.length !== 0 && (
                <div className={style.extraButtons}>
                  <Tooltip title="preview">
                    <PreviewIcon className={style.previewIcon}></PreviewIcon>
                  </Tooltip>
                  <Tooltip title="Lock Document">
                    <LockedIcon></LockedIcon>
                  </Tooltip>
                  <Tooltip title="Unlock Document">
                    <UnLocked></UnLocked>
                  </Tooltip>
                  <Tooltip title="Save Local">
                    <Save style={{ color: '#3CD27D' }} onClick={onSaveHandler}></Save>
                  </Tooltip>
                  <Tooltip title="Save to Cloud">
                    <Backup
                      style={{ color: '#3CD27D' }}
                      onClick={handleSaveToCloud}
                    ></Backup>
                  </Tooltip>
                </div>
              )}
            </div>
            <div>
              <HaEditorComponent tinymceEditoryRef={tinymceEditoryRef}></HaEditorComponent>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HomeComponent;
