import { ProjectManagementServiceClient } from '@hebbale.academy/project-manager-proto/pb/ha/pm/v1/project_grpc_web_pb';
import { ProjectContentManagementServiceClient } from '@hebbale.academy/project-manager-proto/pb/ha/pm/v1/project_content_grpc_web_pb';
import {
  AcquireContentLockRequest,
  UnlockContentRequest,
} from '@hebbale.academy/project-manager-proto/pb/ha/pm/v1/project_content_pb';
import {
  GetItemsResquest,
  GetItemsResponse,
  GetItemContentRequest,
  ItemRequest,
  GetProjectTemplatesRequest,
} from '@hebbale.academy/project-manager-proto/pb/ha/pm/v1/project_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { RpcError } from 'grpc-web';
import { authService } from '../auth/auth.service';

// Todo: Have a utils to read host url from environment variable...
export function getRequestHost() {
  return process.env.REACT_APP_PROTO_PROJECT_MANAGER_ENDPOINT_URL!;
}

const userProjectManagementServiceClient = new ProjectManagementServiceClient(
  getRequestHost(),
  null,
  null,
);
const projectManagementServiceClient = new ProjectManagementServiceClient(
  getRequestHost(),
  null,
  null,
);
const projectContentManagementServiceClient = new ProjectContentManagementServiceClient(
  getRequestHost(),
  null,
  null,
);

export const ProjectManagementService = {
  getItems: async (itemGroup: string): Promise<GetItemsResponse.AsObject | RpcError> => {
    const getItemsRequest = new GetItemsResquest();
    getItemsRequest.setItemGroup(itemGroup);
    return await new Promise<GetItemsResponse.AsObject | RpcError>((resolve, reject) => {
      projectManagementServiceClient.getItems(getItemsRequest, {}, (error, response) => {
        error ? reject(error) : resolve(response.toObject());
      });
    }).catch((error) => error);
  },
  getItemContent: (itemPath: string, itemGroup: string) => {
    let getItemContentResponse;
    const getItemContentRequest = new GetItemContentRequest();
    getItemContentRequest.setItemGroup(itemGroup);
    getItemContentRequest.setItemPath(itemPath);
    return new Promise<{ itemContent: string }>((resolve, reject) => {
      projectManagementServiceClient.getItemContent(
        getItemContentRequest,
        {},
        (error, response) => {
          if (error) {
            console.error('Error', error);
            reject('error');
          } else {
            getItemContentResponse = response.toObject();
            resolve(getItemContentResponse);
          }
        },
      );
    }).catch((err) => {
      console.error(err);
    });
  },
  updateItem: (
    itemPath: string,
    itemType: string,
    itemContent: string,
    itemGroup: string,
    updatedOn: Timestamp.AsObject,
    contentDocId: string,
  ) => {
    let updateItemContentResponse = {};
    const updateItemContentRequest = new ItemRequest();
    const updatedTime = new Timestamp();
    updatedTime.setSeconds(updatedOn.seconds);
    updatedTime.setNanos(updatedOn.nanos);
    updateItemContentRequest.setItemPath(itemPath);
    updateItemContentRequest.setItemType(parseInt(itemType));
    updateItemContentRequest.setItemContent(itemContent);
    updateItemContentRequest.setItemGroup(itemGroup);
    updateItemContentRequest.setContentDocId(contentDocId);
    updateItemContentRequest.setUpdatedOn(updatedTime);

    return new Promise((resolve, reject) => {
      projectManagementServiceClient.updateItem(updateItemContentRequest, {}, (error, response) => {
        if (error) {
          console.error('Error', error);
          reject('error');
          alert(error.message);
        } else {
          updateItemContentResponse = response.toObject();
          resolve(updateItemContentResponse);
        }
      });
    }).catch((err) => {
      console.error(err);
      return 'error';
    });
  },
  getProjects: () => {
    const getProjectsRequest = new GetProjectTemplatesRequest();
    getProjectsRequest.setDataFiltersList([2]);
    return new Promise((resolve, reject) => {
      userProjectManagementServiceClient.getProjectTemplates(
        getProjectsRequest,
        {},
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            resolve(response.toObject());
          }
        },
      );
    }).catch((error) => {
      return error;
    });
  },
};

export const ProjectContentManagementService = {
  acquireContentLock: async (projectTemplateId: string, taskId: string) => {
    let acquireContentLockResponse = {};
    const acquireContentLockRequest = new AcquireContentLockRequest();
    const metaData = await authService.createMetaData();
    acquireContentLockRequest.setProjectTemplateId(projectTemplateId);
    acquireContentLockRequest.setTaskId(taskId);
    return new Promise((resolve, reject) => {
      projectContentManagementServiceClient.acquireContentLock(
        acquireContentLockRequest,
        metaData,
        (error, response) => {
          if (error) {
            console.error('Error', error);
            reject('error');
            alert(error.message);
          } else {
            acquireContentLockResponse = response.toObject();
            resolve(acquireContentLockResponse);
          }
        },
      );
    }).catch((err) => {
      console.error(err);
      return 'error';
    });
  },
  unlockContentLock: async (projectTemplateId: string, taskId: string) => {
    let unlockContentLockResponse = {};
    const unlockContentLockRequest = new UnlockContentRequest();
    const metaData = await authService.createMetaData();
    unlockContentLockRequest.setProjectTemplateId(projectTemplateId);
    unlockContentLockRequest.setTaskId(taskId);
    return new Promise((resolve, reject) => {
      projectContentManagementServiceClient.unlockContentLock(
        unlockContentLockRequest,
        metaData,
        (error, response) => {
          if (error) {
            console.error('Error', error);
            reject('error');
            alert(error.message);
          } else {
            unlockContentLockResponse = response.toObject();
            resolve(unlockContentLockResponse);
          }
        },
      );
    }).catch((err) => {
      console.error(err);
      return 'error';
    });
  },
};
