import { StaticConfig } from './config.types';

export const getConfig: () => StaticConfig = () => ({
  URL_PREFIX: process.env.REACT_APP_GRPC_PREFIX_URL || '',
  GRPC_ENDPOINT_URL: process.env.REACT_APP_GRPC_ENDPOINT_URL || '',
  AUTHORITY: process.env.REACT_APP_OIDC_AUTH_URL || '',
  CLIENT_ID: process.env.REACT_APP_OIDC_IDENTITY_CLIENT_ID || '',
  REDIRECT_URI: `${process.env.REACT_APP_OIDC_REDIRECT_URL}signin-callback.html`,
  SLIENT_REDIRECT_URI: `${process.env.REACT_APP_OIDC_REDIRECT_URL}silent-renew.html`,
  POST_LOGOUT_REDIRECT_URI: `${process.env.REACT_APP_OIDC_REDIRECT_URL}`,
  SECRET_ID: `${process.env.REACT_APP_OIDC_CLIENT_SECRET}`,
  EDITOR_API_KEY: `${process.env.REACT_APP_TINY_MCE_EDITOR_API_KEY}`,
  STATIC_FILES: `${process.env.REACT_APP_PROJECT_MANAGEMENT_STATIC_URL}`,
});

export function getRequestHost() {
  return `${getConfig().URL_PREFIX}${getConfig().GRPC_ENDPOINT_URL}`;
}
