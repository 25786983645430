import styles from './readme.module.scss';

const ReadMeComponent = () => {
  return (
    <div className={styles.readmeContainer}>
      <h2>Things to know about the cloud editor</h2>
      <div className={styles.sectionContainer}>
        <h4>List of custom plugins present in the editor</h4>
        <ul>
          <li>
            <b>Adminations</b> - Useful to mention points to remember or pointers.
            <ul>
              <li>
                <b>Info</b>
              </li>
              <li>
                <b>Note</b>
              </li>
              <li>
                <b>Tip</b>
              </li>
              <li>
                <b>Check</b>
              </li>
              <li>
                <b>Warning</b>
              </li>
              <li>
                <b>Caution</b>
              </li>
            </ul>
          </li>
          <li>
            <b>Accordion(FAQ)</b> - This provides a collapsible content element which can be useful
            for writing FAQs.
          </li>
          <li>
            <b>Preview</b> - This is a modified version of the default plugin with support for
            accordion.
          </li>
          <li>
            <b>Custom Icons</b> - This plugin can be used to load custom icons for the editor which
            can be used for the menu options.
          </li>
        </ul>
        <p>
          <b>NOTE: </b>The adminations and accordion can be accessed from the overflow (3-dots) menu
          within the toolbar
        </p>
      </div>
      <div className={styles.sectionContainer}>
        <h4>Notes related to the plugins</h4>
        <ul>
          <li>
            <b>Accordion(FAQ)</b> -
            <ul>
              <li>
                It comes with a default template containing heading and content. The accordion is
                <b> expanded</b> or <b>collapsed </b>by clicking on the caret icon(down arrow next
                to heading).
              </li>
              <li>
                To <b>delete</b> an existing accordion, one can select the contents and press the
                <b> DELETE</b> key or simply <b>RIGHT-CLICK</b> on the existing one with the
                <b> CTRL</b> or <b>SHIFT</b> key pressed.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ReadMeComponent;
