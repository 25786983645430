import { BrowserRouter } from 'react-router-dom';
import AppBaseComponent from './components/app-base/app-base.component';
import './global-config.scss';

export const App = () => (
  <BrowserRouter>
    <AppBaseComponent />
  </BrowserRouter>
);

export default App;
