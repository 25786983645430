import { getHtmlForAccordion } from '../../templates/ha-editor-templates';

const addAccordionPlugin = function (editor) {
  editor.on('click', function (event) {
    // event.preventDefault();
    accordionToggle(event);
  });
  editor.on('contextmenu', function (event) {
    removeAccordion(event);
  });
  editor.on('keydown', function (event) {
    console.debug(event);
  });
  editor.ui.registry.addButton('addAccordionPlugin', {
    tooltip: 'FAQ',
    icon: 'ha-icon-faq',
    onAction: () => addAccordionBlock(editor),
  });
  editor.ui.registry.addIcon(
    'ha-icon-faq',
    `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0C8.69231 0 7.69231 1 7.69231 2.30769V6.92308C7.69231 8.23077 8.69231 9.23077 10 9.23077H14.6154L17.6923 12.3077V9.23077C19 9.23077 20 8.23077 20 6.92308V2.30769C20 1 19 0 17.6923 0H10ZM13.2215 2.30769H14.5431L15.8415 6.92308H14.6877L14.3746 5.76923H13.2208L12.9331 6.92308H11.9231L13.2215 2.30769ZM13.8462 3.07692C13.7692 3.38462 13.6831 3.76 13.6054 3.99077L13.39 5H14.3031L14.0862 3.99C13.9331 3.76 13.8462 3.38462 13.8462 3.07692ZM2.30769 7.69231C1 7.69231 0 8.69231 0 10V14.6154C0 15.9231 1 16.9231 2.30769 16.9231V20L5.38462 16.9231H10C11.3077 16.9231 12.3077 15.9231 12.3077 14.6154V10H10C8.53846 10 7.38 9 6.99538 7.69231H2.30769ZM5.84154 9.92769C7.14923 9.92769 7.76462 11.0046 7.76462 12.2354C7.76462 13.3123 7.39462 13.9954 6.77923 14.3031C7.08692 14.4569 7.45154 14.5385 7.83615 14.6154L7.54846 15.3846C7.01 15.2308 6.45154 14.9908 5.91308 14.7592C5.83615 14.6823 5.70154 14.6877 5.62462 14.6877C4.70154 14.6108 3.84615 13.8462 3.84615 12.3077C3.84615 11 4.61077 9.92769 5.84154 9.92769V9.92769ZM5.84154 10.7692C5.22615 10.7692 4.92769 11.4615 4.92769 12.3077C4.92769 13.2308 5.22615 13.8462 5.84154 13.8462C6.45692 13.8462 6.77846 13.1538 6.77846 12.3077C6.77846 11.4615 6.45692 10.7692 5.84154 10.7692V10.7692Z" fill="#C6D3E7"/>
    </svg>`,
  );
  function addAccordionBlock(editor) {
    editor.insertContent(getHtmlForAccordion());
  }
};

const accordionToggle = (event) => {
  if (event.target && event.target.className.includes('accordion-heading-button')) {
    const panel = event.target.nextElementSibling;
    if (panel.style.display === 'none') {
      panel.style.display = 'block';
      event.target.classList.replace('caret-icon', 'caret-icon-rotated');
    } else {
      panel.style.display = 'none';
      event.target.classList.replace('caret-icon-rotated', 'caret-icon');
    }
  }
};

const removeAccordion = (event) => {
  if (event.target && event.target.className.includes('accordion-heading-button')) {
    if (event.ctrlKey === true || event.shiftKey === true) {
      event.preventDefault();
      event.target.parentElement.remove();
    }
  }
};

export { addAccordionPlugin };
