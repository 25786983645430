// TODO : clean up below code
import React, { useEffect, useState } from 'react';
import { ProjectManagementService } from '../../services/project-manager/project-manager.service';
import FolderTree from 'react-folder-tree';
import '../../assets/react-folder-tree.styles.css';
import style from './project-explorer.module.scss';
import { Folder, InsertDriveFileOutlined, KeyboardArrowDown } from '@mui/icons-material';
import { LOADING_TEXTS } from '../../utils/enum';
import { Base64, setItemPropertiesToSessionStorage } from '../../utils/utils';
import EditConfirmationBox from '../dialog-box/edit-confirmation';
import { NodeDataType, ProjectExplorerComponentProps } from './project-explorer.types';

const ProjectExplorerComponent = ({
  filesAndFoldersData,
  saveContentToCloud,
  tinymceEditoryRef,
  activeMenuItem,
  selectedFileName,
  loadedHtmlContent,
  setLoadedHtmlContent,
  setSelectedFileName,
}: ProjectExplorerComponentProps) => {
  useEffect(() => {
    setItemPropertiesToSessionStorage();
  }, []);

  const [loadingText, setLoadingText] = useState<string>('');
  const [activeNodeData, setActiveNodeData] = useState<NodeDataType>({
    name: '',
    itemPath: '',
  });
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);

  useEffect(() => {
    const animateLoadingText = () => {
      !filesAndFoldersData &&
        setLoadingText((prevLoadingText) => {
          if (prevLoadingText === `${LOADING_TEXTS.PROJECTS}...`) {
            return LOADING_TEXTS.PROJECTS;
          } else {
            return `${prevLoadingText}.`;
          }
        });
    };
    setLoadingText(LOADING_TEXTS.PROJECTS);
    const setAnimationInterval = !filesAndFoldersData && setInterval(animateLoadingText, 750);
    const clearAnimationOnTimeout =
      setAnimationInterval &&
      setTimeout(() => {
        clearInterval(setAnimationInterval);
        setLoadingText('Failed to load projects. Click on Refresh to try again..');
      }, 20000);

    const clearIntervalAndTimeout = () => {
      if (setAnimationInterval && clearAnimationOnTimeout) {
        clearInterval(setAnimationInterval);
        clearTimeout(clearAnimationOnTimeout);
      }
    };

    return clearIntervalAndTimeout;
  }, [filesAndFoldersData]);

  const handleDialogClose = () => {
    setShowDialogBox(false);
    getFileContentAndSetFilename(
      activeNodeData,
      `${activeNodeData.itemPath.slice(1)}/${activeNodeData.name}`,
    );
  };

  const handleDialogSave = async () => {
    setShowDialogBox(false);
    const saveSuccessful = await saveContentToCloud();
    if (saveSuccessful) {
      getFileContentAndSetFilename(
        activeNodeData,
        `${activeNodeData.itemPath.slice(1)}/${activeNodeData.name}`,
      );
    }
  };

  const setEditorContent = (dataToSet: string) => {
    tinymceEditoryRef.current!.setContent(Base64._utf8_decode(atob(dataToSet)), {
      format: 'raw',
    });
  };

  const getItemContent = async (contentPathName: string) => {
    const getItemContent: any = await ProjectManagementService.getItemContent(
      contentPathName,
      activeMenuItem,
    );
    // we need to clear the undoManager stack after changing the editor content to not get the previous data on undo.
    tinymceEditoryRef.current!.undoManager.clear();
    setEditorContent(getItemContent.itemContent);
    setLoadedHtmlContent(tinymceEditoryRef.current!.getContent());
  };

  const getFileContentAndSetFilename = (nodeData: NodeDataType, filePath: string) => {
    setSelectedFileName(
      `Project Name : ${nodeData.itemPath.split('/')[1]} | File Name : ${nodeData.name}`,
    );
    getItemContent(filePath);
    setItemPropertiesToSessionStorage(nodeData.itemPath.slice(1), nodeData.name, '2');
  };

  const onNameClick = ({
    defaultOnClick,
    nodeData,
  }: {
    defaultOnClick: () => void;
    nodeData: NodeDataType;
  }) => {
    defaultOnClick();
    if (nodeData.itemType === 2) {
      const filePath = `${nodeData.itemPath.slice(1)}/${nodeData.name}`;
      if (!filePath.includes('.html')) {
        alert('Only html file editing is supported');
      } else {
        setActiveNodeData({ name: nodeData.name, itemPath: nodeData.itemPath });
        const currentHtmlContent = tinymceEditoryRef.current!.getContent({ format: 'html' });
        if (currentHtmlContent && loadedHtmlContent && loadedHtmlContent !== currentHtmlContent) {
          setShowDialogBox(true);
        } else {
          getFileContentAndSetFilename(nodeData, filePath);
        }
      }
    }
  };

  const FolderIcon = ({ onClick: defaultOnClick }) => {
    return (
      <Folder
        onClick={defaultOnClick}
        fontSize="medium"
        className={style.iconPositionFix}
        style={{ color: '#FFCE38' }}
      />
    );
  };

  const FolderOpenIcon = FolderIcon;

  const FileIcon = ({ onClick: defaultOnClick }) => {
    return (
      <InsertDriveFileOutlined
        onClick={defaultOnClick}
        fontSize="medium"
        className={style.iconPositionFix}
      />
    );
  };

  const CaretRightIcon = ({ onClick: defaultOnClick }) => {
    return (
      <KeyboardArrowDown onClick={defaultOnClick} fontSize="medium" className={style.arrowRight} />
    );
  };

  const CaretDownIcon = ({ onClick: defaultOnClick }) => {
    return (
      <KeyboardArrowDown onClick={defaultOnClick} fontSize="medium" className={style.arrowDown} />
    );
  };

  // Enable this functionalists in next up coming sprints
  const EditIcon = (...args) => null;
  const DeleteIcon = (...args) => null;
  const CancelIcon = (...args) => null;
  const AddFileIcon = (...args) => null;
  const AddFolderIcon = (...args) => null;

  return (
    <React.Fragment>
      {filesAndFoldersData ? (
        <div className={style.folderTreeContainer}>
          <FolderTree
            data={filesAndFoldersData}
            iconComponents={{
              FolderIcon,
              FolderOpenIcon,
              FileIcon,
              CaretDownIcon,
              CaretRightIcon,
              EditIcon,
              DeleteIcon,
              CancelIcon,
              AddFileIcon,
              AddFolderIcon,
            }}
            onNameClick={onNameClick}
            initOpenStatus="closed"
            showCheckbox={false}
          />
        </div>
      ) : (
        <p className={style.loadingText}>{loadingText}</p>
      )}
      {showDialogBox && (
        <EditConfirmationBox
          showConfirmationStatus={showDialogBox}
          selectedProjectFileInfo={selectedFileName}
          handleClose={handleDialogClose}
          handleSave={handleDialogSave}
        ></EditConfirmationBox>
      )}
    </React.Fragment>
  );
};

export default ProjectExplorerComponent;
