export type HomeComponentProps = {
  activeMenuItem: string;
  showToast: (message: string) => void;
};

export type DialogBoxStateType = {
  isVisible: boolean;
  action: DialogBoxActions;
};

export enum DialogBoxActions {
  ON_REFRESH_HANDLER = 'onRefreshHandler',
  HANDLE_SAVE_TO_CLOUD = 'handleSaveToCloud',
}

export type ProjectDataType = {
  data: {
    id: string;
    name: string;
    description: string;
    level: number;
    stacksList: stacksList;
    durationInHours: number;
    projectActivitiesList: projectActivitiesList;
    labelsList: labelsList;
    thumbnailUrl: string;
    previewUrl: string;
    faqUrl: string;
    projectPrimersList: [];
    creditHours: number;
    skilledHours: number;
    facultyAidUrl: string;
  };
  status: 1;
  createdTime: Time;
  updatedTime: Time;
  releaseLabel: string;
};

export type labelsList = {
  key: string;
  value: string;
}[];

export type stacksList = {
  category: string;
  technologiesList: string[];
}[];

export type projectActivitiesList = {
  id: string;
  name: string;
  description: string;
  durationInMins: number;
  projectActivityTasksList: projectActivityTasksList;
}[];

export type projectActivityTasksList = {
  id: string;
  taskContentUrl: string;
  durationInMins: number;
  paidTask: boolean;
  name: string;
  description: string;
  type: number;
}[];

export type Time = {
  seconds: number;
  nanos: number;
};
