import { Log, User, UserManager } from 'oidc-client';
import { getConfig } from '../../utils/config';
import { CreateMetaData } from './auth.type';

const {
  AUTHORITY,
  CLIENT_ID,
  REDIRECT_URI,
  SLIENT_REDIRECT_URI,
  POST_LOGOUT_REDIRECT_URI,
  SECRET_ID,
} = getConfig();

let authServiceInstance: AuthService;

export class AuthService {
  public userManager: UserManager;

  constructor() {
    const settings = {
      authority: AUTHORITY,
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      silent_redirect_uri: SLIENT_REDIRECT_URI,
      post_logout_redirect_uri: POST_LOGOUT_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid profile',
      client_secret: SECRET_ID,
      automaticSilentRenew: true,
      accessTokenExpiringNotificationTime: 300,
    };
    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
    this.registerEvents();
  }
  registerEvents() {
    this.userManager.events.addAccessTokenExpired((evt) => {
      console.info('Token Expired', evt);
      this.renewToken();
    });
    this.userManager.events.addSilentRenewError((evt) => {
      console.error('Silent renewal failed', evt);
      this.login();
    });
    this.userManager.events.addUserSignedOut(() => {
      console.info('User logged out');
      this.login();
    });
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    window.localStorage.setItem('redirectUrl', window.location.href);
    return this.userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public createMetaData(spaceId?: number): Promise<CreateMetaData> {
    return this.getUser().then((user: User | null) => {
      const requestHeader = {
        authorization: `Bearer ${user!.access_token}`,
      };
      if (spaceId) requestHeader['space-id'] = spaceId;
      return requestHeader;
    });
  }
}

export const getAuthServiceInstance = () => {
  if (!authServiceInstance) {
    authServiceInstance = new AuthService();
  }
  return authServiceInstance;
};

export const authService = getAuthServiceInstance();
