import React, { useEffect, useRef, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { User } from 'oidc-client';
import NavBarComponent from '../navbar/navbar.component';
import style from './app-base.module.scss';
import { useLocation } from 'react-router-dom';
import HomeComponent from '../../scenes/home/home.component';
import ReadMeComponent from '../../scenes/readme/readme.component';
import { ToastMessageStateType } from './app-base.types';
import HomeComponentV2 from '../../scenes/home/home.componentV2';
import { authService } from '../../services/auth/auth.service';

const AppBaseComponent = () => {
  const [activeMenuItem, setActiveMenuItem] = useState<string>('');
  const [user, setUser] = useState<User>();
  const [toast, setToast] = React.useState<ToastMessageStateType>({
    isVisible: false,
    message: '',
  });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { pathname } = useLocation();

  const showToast = (toastMessage: string) => {
    setToast({ isVisible: true, message: toastMessage });
    timeoutRef.current = setTimeout(() => setToast({ isVisible: false, message: '' }), 4000);
  };

  useEffect(() => {
    authService.getUser().then((user: User | null) => {
      if (user) {
        setUser(user);
      } else {
        authService.login();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authService]);

  useEffect(() => {
    if (!!pathname.toLowerCase().includes('workspace')) {
      document.querySelector('#root')?.classList.add('ws');
    } else {
      document.querySelector('#root')?.classList.remove('ws');
    }
  }, [pathname]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <React.Fragment>
      {user ? (
        <div className={style.container}>
          <p className={style.toast} style={{ visibility: toast.isVisible ? 'visible' : 'hidden' }}>
            {toast.message}
          </p>
          <NavBarComponent setActiveMenuItem={setActiveMenuItem} />
          <main className={style.layoutContainer}>
            {user ? (
              <React.Fragment>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/editor" />
                  </Route>
                  <Route exact path="/editor">
                    <HomeComponent
                      activeMenuItem={activeMenuItem}
                      showToast={showToast}
                    ></HomeComponent>
                  </Route>
                  <Route exact path="/editorV2">
                    <HomeComponentV2
                      activeMenuItem={activeMenuItem}
                      showToast={showToast}
                    ></HomeComponentV2>
                  </Route>
                  <Route exact path="/readme">
                    <ReadMeComponent />
                  </Route>
                </Switch>
              </React.Fragment>
            ) : (
              <Switch>
                <Route exact path="/" />
              </Switch>
            )}
          </main>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </React.Fragment>
  );
};

export default AppBaseComponent;
