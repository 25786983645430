export enum PERMISSIONS {
  NO_ROLES_ASSIGNED = 'NRE',
}

export enum LOADING_TEXTS {
  PROJECTS = 'Loading Projects',
}

export enum ITEM_TYPES {
  FOLDER = 1,
  FILE = 2,
}
