import { getConfig } from '../../../utils/config/config.utils';

const { STATIC_FILES } = getConfig();

const getHTMLTemplate = () => {
  return `<html lang="en" style="color: #12DBD5; padding-left: 16px;
  padding-right: 16px;"> \
      <head> \
        <meta charset="utf-8"> \
        <meta name="viewport" content="width=device-width, initial-scale=1.0"> \
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,400italic,600, 600italic%7CNoto+Serif:400,400italic,700,700italic%7CDroid+Sans+Mono:400,700"> \
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" /> \
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
        <link rel="stylesheet" href="https://storage.googleapis.com/ui-project-manager/static-files/cursor-fix-prism-css.css" /> \
        <script src="https://storage.googleapis.com/ui-project-manager/static-files/ha-prism-js.js"></script>
      </head> \
      <style>
      body {
        font-family: 'Poppins', sans-serif;
        color: #dfe0e4;
        background-color: #0e1825;
      }
      /* admonition styles */
      .admonition-table {
        border: 0px !important;
        table-layout: fixed;
        width: 100%;
        background-color: #2d384e;
        margin-bottom: 10px;
      }
      .admonition-icon {
        border: 0 !important;
        width: 40px;
        padding: 26px !important;
      }
      .admonition-content {
        border: 0px !important;
        margin: 20px 10px !important;
      }
      .paragraph {
        position: relative;
        right: 7px;
        margin: 18px 0;
        padding: 10px 26px;
      }
      .paragraph > p {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: white;
        margin: 0;
      }
      .info-border {
        border-left: 1px solid #10ece5 !important;
      }
      .note-border,
      .tip-border {
        border-left: 1px solid #ffcd38 !important;
      }
      .check-border {
        border-left: 1px solid #3cd27d !important;
      }
      .warning-border,
      .caution-border {
        border-left: 1px solid #fd0000 !important;
      }
      .fa-lightbulb {
        position: relative;
        left: 7px;
      }
      .ha-accordion {
        position: relative;
        margin-bottom: 10px;
      }
      .accordion-heading-button {
        background-color: #1a212d;
        color: white;
        cursor: pointer;
        padding: 3px 18px;
        border: none;
        outline: 0px solid #1a212d !important;
        text-align: left;
        font-size: 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Poppins', sans-serif;
        cursor: pointer !important;
      }
      .caret-icon::after,
      .caret-icon-rotated::after {
        font-family: 'Font Awesome 5 Pro';
        content: '\\f107';
        font-size: 1.5em;
        color: white;
        transition: all 0.1s ease-in-out;
      }
      .caret-icon-rotated::after {
        transform: rotateZ(180deg);
      }
      .accordion-heading-button > p {
        outline: 0px solid #1a212d !important;
        max-width: fit-content;
        min-width: 30px;
      }
      .accordion-panel {
        font-family: 'Poppins', sans-serif;
        padding: 0 18px;
        display: none;
        background-color: #1a212d;
        outline: 0px solid #1a212d !important;
        color: white;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
      }
      .accordion-panel > p {
        font-size: 0.9em;
        color: #c6d3e7;
        outline: 0px solid #1a212d !important;
        min-width: 30px;
      }
      .fa-angle-down {
        float: right;
        margin-top: 18px;
        margin-right: 20px;
        user-select: none;
        font-size: 1.5em;
        user-select: none;
        pointer-events: none;
        color: white;
        transition: all 0.1s ease-in-out;
      }
      .copied-text {
        top: 20px;
      }
      /* if you want to override the ugly blue border for the editor components, use this selector */
      .override-outline {
        outline: 0px solid #1a212d !important;
      }
      ::-webkit-scrollbar {
        width: 0.35rem;
        height: 0.35rem;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
        visibility: hidden;
      }
      ::-webkit-scrollbar-thumb {
        background: #3cd27d;
        border-radius: 0.5rem;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: rgb(60, 210, 125);
      }
      img, video {
        max-width: 900px;
      }
      </style>
      <body onload="onContentLoad()">
        #editorContent
        <script>
        function openFullscreen(elem) {
          elem.requestFullscreen();
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) {
            /* IE11 */
            elem.msRequestFullscreen();
          }
        }

        function closeFullscreen() {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            /* Safari */
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            /* IE11 */
            document.msExitFullscreen();
          }
        }
  
        const handleFullScreen = (event) => {
          if (event.detail == 2) {
            var elem = document.getElementById(event.srcElement.id);
            openFullscreen(elem);
            closeFullscreen();
          }
        };
  
        window.addEventListener("click", handleFullScreen);
        [...document.getElementsByClassName('accordion-panel')].forEach((panel) => panel.style.display = 'none');
        [...document.getElementsByClassName('accordion-heading-button')].forEach((accordionButton) => accordionButton.classList.replace('caret-icon-rotated', 'caret-icon'));
        const handleAccordion = (event) => {
          if (document.getSelection().isCollapsed && event.target) {
            let selectedAccordionButton = event.target;
            let panel = '';
            if (event.target.className.includes('accordion-heading-button')) {
              panel = event.target.nextElementSibling;
            }
            if (event.target.parentElement && [...event.target.parentElement.classList].indexOf('accordion-heading-button') > -1) {
              panel = event.srcElement.parentNode.nextElementSibling;
              selectedAccordionButton = event.srcElement.parentNode;
            }
            if (panel) {
              if (panel.style.display === 'none') {
                panel.style.display = 'block';
                selectedAccordionButton.classList.replace('caret-icon', 'caret-icon-rotated');
              } else {
                panel.style.display = 'none';
                selectedAccordionButton.classList.replace('caret-icon-rotated', 'caret-icon');
              }
            }
          }
        };
        document.addEventListener('click', handleAccordion);
        let activeVideoElement = '';
        function handleVideoPlayPause(event) {
          if (activeVideoElement && event.srcElement !== activeVideoElement) {
            activeVideoElement.pause();
          }
          activeVideoElement = event.srcElement;
        };
        function addPreloadTag(videoTag) {
          videoTag.setAttribute("preload", "metadata");
        }
        // add onplay listener to video elements
        [...document.getElementsByTagName('video')].forEach(videoTag => (videoTag.onplay = (event) => handleVideoPlayPause(event)));
        [...document.getElementsByTagName("video")].forEach((videoTag) => addPreloadTag(videoTag));
      </script>
      <script src="${STATIC_FILES}/micro-service-content.js">        
      </script>
      <script>
        document.addEventListener("DOMContentLoaded", function() {
          const cookies = document.cookie;
          console.log("Cookies inside iframe:", cookies);
        });
      </script>
    </body>\
  </html>`;
};

const getHtmlForAdmination = (icon: string, color: string, title: string) => {
  return `<span>
            <table class="admonition-table">
              <tbody>
                <tr>
                  <td class="admonition-icon mceNonEditable">
                    <div class="${icon} mceNonEditable" title="${title}" style="font-size:2.5em;color:${color};text-shadow:'1px 1px 2px rgba(0,0,0,.5)';"></div>
                  </td>
                  <td class="admonition-content mceNonEditable">
                    <div class="paragraph ${title.toLowerCase()}-border mceEditable override-outline">
                      <p class="override-outline">Content</p>
                    </div>
                    </div>  
                  </td>
                </tr>
              </tbody>
            </table>
          </span>`;
};

const getHtmlForAccordion = () => {
  return `<div class="ha-accordion override-outline mceNonEditable">
            <div class="accordion-heading-button caret-icon override-outline mceNonEditable">
              <p class="mceEditable">Heading</p>
            </div>
            <div class="accordion-panel override-outline mceEditable">
              <p>Content</p>
            </div>
          </div>`;
};

export { getHTMLTemplate, getHtmlForAdmination, getHtmlForAccordion };
